import './css/modal.css';
import { lazy , Suspense} from 'react';
import {  useAppSelector, useAppDispatch } from './app/hooks';
import {
  selectPhoto,
  selectStatus,
  selectStatusMsg,
  selectModal,
  selectFormData,
  setShowModal,
  setAppStatus,
  submitDataAsync,
} from './features/photoState/photoSlice';

import doneImg from './right.png'
import notDoneImg from './wrong.png'

import { RevolvingDot } from  'react-loader-spinner'

const Revolver = () => {
  return (
    <RevolvingDot
      height="100"
      width="100"
      radius = {20}
      color="#4fa94d"
      secondaryColor=''
      ariaLabel="revolving-dot-loading"
      wrapperStyle={{margin:"auto", display:"block"}}
      wrapperClass=""
      visible={true}
      
  />
  );
}


function Modal() {

  const photos = useAppSelector(selectPhoto);
  const status = useAppSelector( selectStatus );
  const statusMsg = useAppSelector(selectStatusMsg);
  const dispModal = useAppSelector(selectModal);
  const formData = useAppSelector(selectFormData);
  const dispatch = useAppDispatch()

  const SVG = lazy(() => import('./SVG'));
  
  const submitForm = () => {
    
    dispatch(submitDataAsync('')).unwrap()
    
    .then((promiseresult) => {

    })
    .catch((rejection) => {
      console.log(rejection)
    })

  }

  const makeChanges = () => {
    dispatch(setAppStatus({status:'idle'}))
    dispatch(setShowModal(false))
  }

  return ( <> { dispModal &&
    <div className="modal">  
      <div className="modal-content"> {  status == 'loading' ? <div className="wrapper"> 
        <Revolver/>
        <p style={{fontSize:16}}>{statusMsg}</p>
        </div>
        :  status == 'failed' ?  
        <div style = {{margin:"auto"}}> 
          <img src = {notDoneImg} style={{maxWidth:"100px", display:"block", margin:"auto"}}/>
          <h1 className='error'>
            {statusMsg}
          </h1>
          <button className='change' onClick={() => makeChanges()}>MAKE CHANGES</button>            
        </div> : status == 'done' ? 
        <div style = {{margin:"auto"}}> 
          <h1 style={{color:"green"}}>
            {statusMsg}
          </h1>
          <img src = {doneImg} style={{maxWidth:"200px", display:"block", margin:"auto"}}/>
        </div> 
        : (photos['crop'] && photos['certi']) ? 
        <Suspense fallback={ <Revolver/> } >
          <>
            <div className="wrapper">
              <SVG certi = {photos['certi']} crop = {photos['crop']} name = {formData.name}/>
            </div>
            <button onClick={() =>submitForm()}>CONFIRM</button>
            <button className='change' onClick={() => makeChanges()}>MAKE CHANGES</button>
          </>
        </Suspense>
      :
      <> 
         <p style={{fontSize:18}}> STLL RENDERING PLEASE RELOAD THE PAGE IF THIS DOSENT GO AWAY </p>
      </>

      }
      </div>
    </div> 
    }
    </>
  );
}

export default Modal;
