import { useEffect, useState } from "react"
import {selectFormGrades, selectFormName, 
    setSchoolLogo, setSchoolUid, 
    setGradeName,  setSchoolName, 
    addFld,        selectSchoolUid,
    selectFormState } from './features/form/formSlice'
import { useAppDispatch, useAppSelector } from './app/hooks';
import { Checkbox } from "@mui/material";
import Modal from './Modal';
import {
  setShowModal,
  setAppStatus
} from './features/photoState/photoSlice';
import { error } from "console";

function BuildForm ()
{
    const [fldtype, setFldType] = useState("text");
    const [fldTitle, setFldTitle] = useState("");
    const [fldOptions, setFldOptions] = useState("");
    const [fldReqd, setFldReqd] = useState(false);

    const gradeNames = useAppSelector(selectFormGrades);
    const schoolName = useAppSelector(selectFormName);
    const schoolUid = useAppSelector(selectSchoolUid);
    const schema = useAppSelector(selectFormState);
    const dispatch = useAppDispatch()

    const addToSchema = () => {
        let newFld:any = {}
        if(fldtype == "text" || fldtype == "select"){
            newFld["type"] = "string"
        } else if(fldtype == "boolean"){
            newFld["type"] = "boolean"
        }

        newFld["title"] = fldTitle;
        
        if(fldtype == "select"){
            if(fldOptions.trim() == "" || fldOptions.split(",").length < 2){
                alert("Please fill in options for select fld")
                return
            }

            newFld["enum"] = fldOptions.trim().split(",").map((value) => value.trim())

        }

        dispatch(addFld({name:fldTitle, data:newFld, required:fldReqd}));
    };

    const submit = () => {
        dispatch(setAppStatus({status:'loading', msg:'Creating Form Data Please Wait'}))
        dispatch(setShowModal(true))
        
        const url = "/data/makeSchool"
        
        fetch(`${url}`, {method: "POST",headers: {
            "Content-Type": "application/json",
          }, body: JSON.stringify(schema)})   
        .then(res => {
            
            return res.json()
        })
        .then(e => {
            if(e.status == "100") {
                
                const schemaCopy = {...schema}
                schemaCopy.logo = ""


                const url = "https://script.google.com/macros/s/AKfycbxxpKwZYPbfd4fQ7HcbPYnQjLWg-t4F7RrC-W71pum7H-758Ugem10afL7Rk4XDiuZwYA/exec"

                fetch(`${url}`, {method: "POST", body: JSON.stringify(schemaCopy)})   
                .then(res => {
                    
                    return res.json()
                })
                .then(e => {
                    console.log(e)
                    if(e.status == "100") {
                                                
                        dispatch(setAppStatus({status:'done', msg:'Form Data Created'}))
                    } else if(e.status == "300") {
                        dispatch(setAppStatus({status:'failed', msg:e.error}))    
                    }
        
                })  // <--- You can retrieve the returned value here.
                .catch(err => { 
                    dispatch(setAppStatus({status:'failed', msg:'Something Went Wrong Retry. If the error persists reload and Retry or contact admin'}))
                });
                
            } else {
                dispatch(setAppStatus({status:'failed', msg:'Something Went Wrong Retry. If the error persists reload and Retry or contact admin'}))
            }

        })  // <--- You can retrieve the returned value here.
        .catch(err => { 
            dispatch(setAppStatus({status:'failed', msg:'Something Went Wrong Retry. If the error persists reload and Retry or contact admin'}))
        });

    }

    const setLogo = (event:any) => {
      
      const fileUploaded = event.target.files[0];
      const reader = new FileReader();
      
      reader.addEventListener(
        "load",
        () => {
            
            dispatch(setSchoolLogo( reader.result as string))
      
        },
        false
      );
    
      if (fileUploaded) {
        reader.readAsDataURL(fileUploaded);
      }

    }
    useEffect(() => {
        dispatch(setShowModal(false))
        dispatch(setAppStatus({status:'idle'}))
    },[]);

    return (
        <>  
        { <Modal/> }
            <div>
                <label>School Name</label>
                <input type="text" value={schoolName} onChange={(e)=>dispatch(setSchoolName(e.target.value ))}></input>
            </div>
            <div>
                <label htmlFor="logo">Logo:</label>
                <input type = "file" onChange={setLogo}/>
            </div>
            <div>
                <label>School Unique Id</label>
                <input type="text" value={schoolUid} onChange={(e)=>dispatch(setSchoolUid(e.target.value ))}></input>
            </div>
            <div>
                <h3>GRADE NAMES:</h3>  
                <input type="text" value={gradeNames[0].split(" ")[1]} onChange={(e)=>dispatch(setGradeName({index:1, value: e.target.value }))}></input>
                <input type="text" value={gradeNames[1].split(" ")[1]} onChange={(e)=>dispatch(setGradeName({index:2, value: e.target.value }))}></input>
                <input type="text" value={gradeNames[2].split(" ")[1]} onChange={(e)=>dispatch(setGradeName({index:3, value: e.target.value }))}></input>
                <input type="text" value={gradeNames[3].split(" ")[1]} onChange={(e)=>dispatch(setGradeName({index:4, value: e.target.value }))}></input>
            </div>
            <div style={{borderTop: "thin solid black", display:"block"}}>
                <div>
                    <label>Field Type</label>
                <select name="FLD TYPE" onChange={(e:any) => {
                    setFldType(e.target.value)  
                }}>
                    <option value="text">Text Field</option>
                    <option value="select">Select Field</option>
                    <option value="boolean">Checkbox</option>
                </select>
                </div>
                <div>
                    <label>Title:</label><input type="text" value={fldTitle} 
                    onChange={(e) => setFldTitle(e.target.value)}/>
                </div>
                <div>
                    <label>Options:</label>
                    <input type="text" value={fldOptions}
                    onChange={(e) => setFldOptions(e.target.value)}/>
                </div>
                <div>
                    <label>Required:</label>
                    <Checkbox  onChange={(e) => setFldReqd(e.target.checked)}/>
                </div>
                <button onClick={()=>{addToSchema()}}>Add</button>

            </div>
            <button onClick={submit}>SUBMIT</button>
        <>
            
        </>
        <>
        
        </>
        
        </>
    )
}

export default BuildForm