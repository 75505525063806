export function fetchPhoto(photo: any, name: string, type: string) {
  const imgarr:any = [...photo]
      
  const url = "https://pycrop02-heonmsyjsq-el.a.run.app/makefile"
  const qs = new URLSearchParams({filename:  name, mimeType: type});
  return new Promise<{ data: any[] }>((resolve,reject) =>
    fetch(`${url}?${qs}`, {method: "POST", body: JSON.stringify(imgarr)})   
    .then(res => res.json())
    .then(e => {
      
      if(e.status == 100){
        resolve({data :[e.cropped, e.resized]})  
      } else {
        
        reject(e.status)
      }
    })  // <--- You can retrieve the returned value here.
    .catch(err => { 
      reject(err)
    })
 
  );
}



export function fetchSubmitData(photo: [string, string, string], name: string, type: string, data: any) {
  const imgarr:any = [...photo]
      
  const url = "https://script.google.com/macros/s/AKfycbzxCcK68mGta9toXRCaCMwTMJOXACcC_7sKSGdL-D4-cjZbUmyENcVMTHjGGHLzPUti/exec"
  const qs = new URLSearchParams({filename:  "", mimeType: type});
  return new Promise<{ data: any[] }>((resolve,reject) =>
    fetch(`${url}?${qs}`, {method: "POST", body: JSON.stringify({'data': photo, 
    'entries':  data})})   
    .then(res => res.json())
    .then(e => {
      
      if(e["Status"] == "GOOD"){
        resolve({data :e["Status"]})  
      } else {
        
        reject(e["Error"])
      }
    })  // <--- You can retrieve the returned value here.
    .catch(err => { 
      reject(err)
    })
 
  );
}
