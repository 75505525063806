import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { RJSFSchema } from '@rjsf/utils';
import exp from 'constants';

const schema:RJSFSchema = {

  "title": "",
  "type": "object",
  "required": [
    "name",
    "grade",
    "gender",
    "dob",
    "imageFile"
  ],
  "properties": {
    "name": {
      "type": "string",
      "title": "Display Name (Your Kids Name)",
      "maxLength": 18
    },
    "grade": {
      "type": "string",
      "title": "Grade",
      "enum": [
        "01 Playgroup",
        "02 Nursery",
        "03 LKG",
        "04 UKG"
      ]
    },
    "gender": {
      "type": "string",
      "title": "Gender",
      "enum": [
        "BOY",
        "GIRL"
      ]
    },
    "dob": {
      "type": "string",
      "title": "Date of Birth"
    },
    "imageFile": {
      "type": "string",
      "title": "Kid's Photo"
    }
  }
}

function zeroPad(num:number, places:number) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

export interface FormState {
  schema: any;
  logo: string,
  uid: string
}

const initialState: FormState = {
  schema: schema,
  logo: "",
  uid: ""
};

export const  formSlice = createSlice({
  name: 'counter',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setGradeName: (state, action:PayloadAction<{index:number, value:string}>) => {
      state.schema.properties.grade.enum[action.payload.index - 1] = 
      zeroPad(action.payload.index, 2) + " " + action.payload.value;
      console.log(state)
    },
    addFld:(state, action:PayloadAction<{name:string, data:any, required:boolean}>) => {
      state.schema.properties[action.payload.name] = action.payload.data;

      if(action.payload.required) {
        state.schema.required.push(action.payload.name)
      }

    }, 
    setSchoolName:(state, action:PayloadAction<string>)=>{
      state.schema.title = action.payload
    },
    setSchoolLogo:(state, action:PayloadAction<string>)=>{
      state.logo = action.payload
    },
    setSchoolUid:(state, action:PayloadAction<string>)=>{
      state.uid = action.payload
    }
  },
});

export const { setGradeName, setSchoolName,addFld, setSchoolLogo, setSchoolUid} = formSlice.actions;

export const selectFormState = (state: RootState) => state.form;
export const selectFormGrades = (state:RootState) => state.form.schema.properties.grade.enum;
export const selectFormName = (state:RootState) => state.form.schema.title;
export const selectSchoolUid = (state:RootState) => state.form.uid;

export default formSlice.reducer;
