import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchPhoto } from './photoAPI';
import { fetchSubmitData } from './photoAPI';


 export interface photoPayload {
  photo: string | ArrayBuffer | null;
  photoType: 'ip' | 'crop' | 'certi'; 
}

export interface PhotoState {
  allPhotos: any;
  status: 'idle' | 'loading' | 'failed' | 'done';
  statusMsg : string,
  showModal : boolean,
  formData: any
}

const initialState: PhotoState = {
  allPhotos: {'ip': '', 'ipPayload': '' , 'crop': '', 'certi' : '', 'fileName': '', 'mimeType' : ''},
  status: 'loading',
  statusMsg : 'Loading Form Please Wait',
  showModal: true,
  formData: {}
};

function _arrayBufferToBase64( bytes:any ) {
  var binary = '';
  var len = bytes.length;
  for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
  }
  return btoa( binary );
  
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getPhotoAsync = createAsyncThunk(
  'photo/fetchPhoto',
  async (photo:any,{getState}) => {
    const root:any = getState()
    console.log(root.photo);
    const response = await fetchPhoto(root.photo.allPhotos['ip'], root.photo.allPhotos['fileName'], root.photo.allPhotos['mimeType']);
    return response.data  
  }
);

function constructEntries(data:any)
{
  let vettedForm:any = {}
  vettedForm['dname'] = data['name']
  vettedForm['grade'] = data['grade'] 
  vettedForm['gender'] = data['gender']
  vettedForm['dob'] = data['dob']
  vettedForm['schoolid'] = data['uid']
  

  for(const property in data) {
    if(!["name", "grade", "gender", "dob", "imageFile"].includes(property)) {
      vettedForm[property] = data[property]      
    }
  }
  return vettedForm;
}


export const submitDataAsync = createAsyncThunk(
  'photo/submitData',
  async (photo:any,{getState}) => {
    const root:any = getState()
    console.log(root.photo.formData);
    const response = await fetchSubmitData([root.photo.allPhotos['ipPayload'],
    root.photo.allPhotos['crop'], root.photo.allPhotos['certi']], 
    root.photo.allPhotos['fileName'], root.photo.allPhotos['mimeType'], constructEntries(root.photo.formData));
    return response.data  
  }
);

export const photoSlice = createSlice({
  name: 'photo',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPhoto: (state, action: PayloadAction<photoPayload>) => {
      
      state.allPhotos[action.payload.photoType] =  action.payload.photo;

      if(action.payload.photoType == 'ip') {
        state.allPhotos['ipPayload'] =  _arrayBufferToBase64 (action.payload.photo)
      }
    },
    setPhotoMetaData: (state, action: PayloadAction<any>) => {
      
      state.allPhotos['fileName'] = action.payload.fileName;
      state.allPhotos['mimeType'] = action.payload.mimeType;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setAppStatus: (state, action: PayloadAction<{status:'idle' | 'loading' | 'failed' | 'done', msg? : string}>) => {
      state.status = action.payload.status;
      state.statusMsg = action.payload.msg ? action.payload.msg : '';
    },    
    setAppStatusMsg: (state, action: PayloadAction<string>) => {
      state.statusMsg = action.payload;
    },
    setFormData: (state, action: PayloadAction<{data:any, id:string}>) => {
      state.formData = action.payload.data;
      state.formData.uid = action.payload.id;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getPhotoAsync.pending, (state) => {
        state.status = 'loading';
        state.statusMsg = "Please wait while we Process the Image (Approx Time: 1 minute)"
      })
      .addCase(getPhotoAsync.fulfilled, (state, action:any) => {
        state.status = 'idle';
        state.statusMsg = ''
        state.allPhotos['crop'] = _arrayBufferToBase64(action.payload[0]);
        state.allPhotos['certi'] = _arrayBufferToBase64(action.payload[1]);
      })
      .addCase(getPhotoAsync.rejected, (state, action) => {
        state.status = 'failed';
        if( action.error.message == "200") {
          state.statusMsg = "PHOTO REJECTED: Please select another Clearer/Proper picture of your Kid. Adhere to the guidelines Stated"
        } else {
          state.statusMsg = "PHOTO REJECTED: Invalid Image Format. Select Another Image"
        }
      }).addCase(submitDataAsync.pending, (state) => {
        state.status = 'loading';
        state.statusMsg = "Submitting Data Please Wait"
      })
      .addCase(submitDataAsync.fulfilled, (state, action:any) => {
        state.status = 'done';
        state.statusMsg = 'Thankyou Data Successfully Submitted';
      })
      .addCase(submitDataAsync.rejected, (state, action) => {
        state.status = 'failed';
        console.log(action.error.message)
        state.statusMsg = " Data submission unsuccesful.Sorry for the inconvinience please reload and submit The data again";
      });
  },
});

export const { setPhoto, setPhotoMetaData, setShowModal, setAppStatus, setFormData, setAppStatusMsg } = photoSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPhoto = (state: RootState) => state.photo.allPhotos;
export const selectModal = (state: RootState) => state.photo.showModal;
export const selectStatus = (state: RootState) => state.photo.status;
export const selectFormData = (state: RootState) => state.photo.formData;
export const selectStatusMsg = (state: RootState) => state.photo.statusMsg;

export default photoSlice.reducer;
