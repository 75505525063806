
function UploadBtn() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.36 40.18">
      <path
        fill="#707274"
        d="M51.52 36.39L16.66 36.39 16.66 23.96 21.91 23.96 21.91 31.14 46.27 31.14 46.27 23.96 51.52 23.96 51.52 36.39z"
      ></path>
      <path
        fill="#3966a5"
        d="M28.22 14.7L28.22 27.74 39.96 27.74 39.96 14.7 44.99 14.7 34.09 3.79 23.19 14.7 28.22 14.7z"
      ></path>
      <text
        fontFamily="MyriadPro-Regular, 'Myriad Pro'"
        fontSize="9.98"
        transform="translate(52.4 18.61)"
      >
        <tspan x="0" y="0">
          UP
        </tspan>
        <tspan x="11.77" y="0" letterSpacing="-.04em">
          L
        </tspan>
        <tspan x="16.09" y="0" letterSpacing="-.01em">
          O
        </tspan>
        <tspan x="22.83" y="0">
          AD KI
        </tspan>
        <tspan x="45.5" y="0" letterSpacing="0em">
          D
        </tspan>
        <tspan x="52.24" y="0" letterSpacing="0em">
          ’
        </tspan>
        <tspan x="54.23" y="0">
          S{" "}
        </tspan>
        <tspan x="15.62" y="11.98">
          PH
        </tspan>
        <tspan x="27.44" y="11.98" letterSpacing="-.02em">
          O
        </tspan>
        <tspan x="34.07" y="11.98" letterSpacing="-.03em">
          T
        </tspan>
        <tspan x="38.76" y="11.98" letterSpacing="0em">
          O
        </tspan>
      </text>
      <rect
        width="124.36"
        height="39.18"
        x="0.5"
        y="0.5"
        fill="none"
        stroke="#304295"
        strokeMiterlimit="10"
        rx="13"
        ry="13"
      ></rect>
    </svg>
  );
}

export default UploadBtn;
