import React, { LegacyRef, useState } from 'react';
import UploadBtn from './UploadBtn'
import {  useAppDispatch } from './app/hooks';
import guides from './guides.jpg'
import {
  setPhoto,
  setPhotoMetaData,
  photoPayload,
  setShowModal,
  setAppStatus,
} from './features/photoState/photoSlice';

export const FileUploader = (props:any) => {
    
    const dispatch = useAppDispatch();
    
    const hiddenFileInput:LegacyRef<HTMLInputElement>= React.useRef(null);
    const [fileName, setFileName] = useState("")
    const handleClick = () => {
      hiddenFileInput.current && hiddenFileInput.current.click();
    };
  
    const handleChange = (event:any) => {
      
      dispatch(setShowModal(true))
      dispatch(setAppStatus({status:'loading', msg : 'Please Wait Uploading Image'}))
      
      const fileUploaded = event.target.files[0];
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
            const payloadData: photoPayload = {photo: new Uint8Array(reader.result as ArrayBuffer), photoType:'ip'}
            const metadata:any = {fileName: fileUploaded.name, mimeType: fileUploaded.type}

            dispatch(setPhoto(payloadData)) 
            dispatch(setPhotoMetaData(metadata))
            
            props.onChange(fileUploaded.name)
                  
            dispatch(setShowModal(false))
            dispatch(setAppStatus({status:'idle'}))
            
            
            setFileName(fileUploaded.name)
  
        },
        false
      );
    
      if (fileUploaded) {
        reader.readAsArrayBuffer(fileUploaded);
      } else {
        dispatch(setShowModal(false))
        dispatch(setAppStatus({status: 'idle'}))
      }
      
    };
  
  
    return (
      
      <div style={{display:"block"}}>
        <img src={guides} style={{maxWidth:"250px", display: "block", margin:"auto"}}></img>
        <span style={{margin:"auto"}} className='uploadBtn' onClick={handleClick}>
        <input type="file"
               ref={hiddenFileInput}
               onChange={handleChange}
               style={{display:'none'}} 
        /> 
        
          <UploadBtn />
        </span>
        
        <p style={{margin:"auto"}}>{fileName}</p>
      </div>
    );
  };
  