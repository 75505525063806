import { configureStore, ThunkAction, Action , applyMiddleware} from '@reduxjs/toolkit';
import formReducer from '../features/form/formSlice';
import photoReducer from '../features/photoState/photoSlice';
export const store = configureStore({
  
  reducer: {
    photo: photoReducer,
    form: formReducer,
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})
});


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
