import './css/builder.css';
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/mui';
import { FileUploader } from './FileUploader';
import BuildForm from './BuildForm'
import {selectFormState} from './features/form/formSlice'
import { useAppSelector } from './app/hooks';

const uiSchema = {
  "dob": {
    'ui:widget': 'date',
    'ui:style': { width: 180 },
  },
  "imageFile": {
    "ui:widget":FileUploader
  },
  
};


function App() {

  const schema = useAppSelector(selectFormState)
  
  return (
  <div>
    <div className="main-form">
      <div className='left-part'>
        <BuildForm/>
      </div>
      <form>
        <h4> School Unique ID: {schema.uid}</h4>
        <img style={{display:"block", margin:"auto", maxWidth:"100px"}} src={schema.logo}/>
        <Form
          schema={schema.schema}
          uiSchema={uiSchema}
          validator={validator}
          onChange={() => {}}
          onSubmit={() => {}}
          onError={() => {}}
          noHtml5Validate={true}
        />
      </form>
    </div>
  </div>
  );
}

export default App;
