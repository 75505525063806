import './css/main.css';
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/mui';
import Modal from './Modal';
import { FileUploader } from './FileUploader';
import {  useAppDispatch } from './app/hooks';
import {
  getPhotoAsync,
  setShowModal,
  setFormData,
  setAppStatus
} from './features/photoState/photoSlice';
import {useEffect, useState} from 'react';

import { useParams } from "react-router-dom";

const uiSchema = {
  "dob": {
    'ui:widget': 'date',
    'ui:style': { width: 180 },
  },
  "imageFile": {
    "ui:widget":FileUploader
  },
  
};

const log = (type:any) => console.log.bind(console, type);



function App() {

  const [schema, setSchema] = useState({schema:{}, logo:"", uid: ""})  
  const { id } = useParams();

  console.log(id)
  
  useEffect ( () => {

    const url = `/data/${id}`
    
      fetch(`${url}`, {method: "GET"})   
      .then(res => {
        return res.json()
      })
      .then(e => {
        if(true) {
          let data = JSON.parse(e)
          setSchema(data)
          dispatch(setAppStatus({status:"idle"}))
          dispatch(setShowModal(false))
        } 
      })  
      .catch(err => {
        dispatch(setAppStatus({status:"failed", msg: "Please re-check the URL and contact your School coordinator if the error persists"}))
      })
/*  
      import(`./jsons/${id}.json`)
      .then((res) => {
        setSchema(res)
        dispatch(setAppStatus({status:"idle"}))
        dispatch(setShowModal(false))
      })
      .catch(_ => {
        dispatch(setAppStatus({status:"failed", msg: "Please re-check the URL and contact your School coordinator if the error persists"}))
      });
*/
  },[id]);
  
  const dispatch = useAppDispatch()

  const gatherPhotos = (formData:any, schoolId:string) => {

    dispatch(setShowModal(true))
    dispatch(setFormData({data:formData, id:schoolId}))
    dispatch(getPhotoAsync('')).unwrap()
    
    .then((promiseresult) => {

      console.log(promiseresult)
    })
    .catch((rejection) => {
      
      console.log(rejection)
    })
  };

  return (
  <div>
   { <Modal/> }
    <div className="main-block">
      <img style={{display:"block", margin:"auto", maxWidth:"100px"}} src={schema.logo}/>
      {schema.uid != '' && <Form
        schema={schema.schema}
        uiSchema={uiSchema}
        validator={validator}
        onChange={(data, id)=>console.log(data)}
        onSubmit={(data) => gatherPhotos(data.formData, schema.uid)}
        onError={log('errors')}
        noHtml5Validate={true}
      />
      }
    
    </div>
  </div>
  );
}

export default App;
